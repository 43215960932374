<template>
	<v-container>
		<v-data-table
			:headers="headers"
			:items="items"
			:items-per-page="itemsPerPage"
			hide-default-footer
			class="elevation-1"
			:loading="syncing"
		>
			<template v-slot:body v-if="syncing">
				<tbody>
					<tr class="loader-box">
						<div class="loader-spin">
							<v-progress-circular
								indeterminate
								color="primary"
							></v-progress-circular>
						</div>
					</tr>
				</tbody>
			</template>
			<template v-slot:top>
				<v-toolbar flat color="#2961ff" dark>
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<v-icon
						@click="loadData"
						small
						v-if="sort == 'ref' && sortDir == 1"
						class="mr-4"
						:class="syncing || resyncing ? 'rotate' : ''"
						>fas fa-sync</v-icon
					>
					<v-text-field
						v-model="search"
						label="Search"
						flat
						dark
						solo-inverted
						hide-details
						clearable
						clear-icon="fas fa-times-circle"
					></v-text-field>
					<v-spacer></v-spacer>
				</v-toolbar>
			</template>
			<template v-slot:[`header.ref`]="{ header }">
				<p class="table-header-item">
					{{ header.text }}
					<v-icon small v-if="sort == 'ref' && sortDir == 1"
						>fas fa-sort-down</v-icon
					>
					<v-icon small v-if="sort == 'ref' && sortDir == -1"
						>fas fa-sort-up</v-icon
					>
				</p>
			</template>
			<template v-slot:[`header.nid`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`header.name`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`header.nationality`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`header.phone`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`header.insA`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`header.insB`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`header.status`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`header.actions`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`header.timer`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`header.type`]="{ header }">
				<p class="table-header-item">{{ header.text }}</p>
			</template>
			<template v-slot:[`item.type`]="{ item }">
				{{ item.type || "Not Set" }}
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<v-chip
					:color="getColor(item.status).bg"
					:dark="!getColor(item.status).dark"
				>
					{{ item.status }}
				</v-chip>
			</template>
			<template v-slot:[`item.timer`]="{ item }">
				<template
					v-if="
						item.status == 'New' || item.status == 'Pending Payment'
					"
					dist="ss"
				>
					{{ new Date(item.createdAt).timer(now) }}
				</template>
				<template v-else>
					Updated: {{ new Date(item.updatedAt).formatMMDDYYYYHHMM() }}
				</template>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<template
					v-if="
						item.status.toUpperCase() !== 'CANCELED' &&
						item.status.toUpperCase() !== 'COMPLETED'
					"
				>
					<div
						v-if="
							item.editinfo &&
							Math.floor(Date.now() / 1000) -
								item.editinfo.timestamps <
								15
						"
						class="editingtablenfo"
					>
						<div
							v-if="
								item.editinfo.editUser.id ==
								$store.getters.getUser._id
							"
							style="margin-bottom: 20px"
						>
							<v-icon @click="editItem(item)" small class="mr-2"
								>fas fa-pen</v-icon
							>
						</div>
						<v-icon
							v-if="
								item.editinfo.editUser.id !==
								$store.getters.getUser._id
							"
							>fas fa-lock</v-icon
						>{{ item.editinfo.editUser.name }}
					</div>
					<div v-else class="editingtablenfo2">
						<v-icon @click="editItem(item)" small class="mr-2"
							>fas fa-pen</v-icon
						>
					</div>
				</template>
			</template>
		</v-data-table>
		<div class="table-footer">
			Total: {{ total }}
			<v-pagination
				v-model="page"
				:length="pageCount"
				:total-visible="7"
				prev-icon="fas fa-caret-left"
				next-icon="fas fa-caret-right"
				color="blue accent-4"
				class="mt-4 mb-4"
				:disabled="syncing"
			></v-pagination>
		</div>
		<v-dialog v-model="dialogCancel" max-width="500px">
			<v-card>
				<v-card-title class="text-h5">
					<div class="text-center w-full">
						<v-icon class="mb-4 mt-4" color="red" size="50"
							>fas fa-exclamation-circle</v-icon
						>
						<p>Are you sure you want to Cacnel?</p>
					</div>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDelete"
						>Cancel</v-btn
					>
					<v-btn color="blue darken-1" text @click="cancelItemConfirm"
						>Yes</v-btn
					>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogEdit" max-width="800px">
			<v-card v-if="items[editedIndex]">
				<v-card-title class="text-h5">
					<div class="text-center w-full">
						<p>Edit {{ editedItem.ref }}</p>
					</div>
				</v-card-title>
				<validation-observer ref="observer" v-slot="{ invalid }">
					<form @submit.prevent="submit" class="pa-5">
						<v-text-field
							v-model="editedItem._id"
							label="ID"
							required
							readonly
							disabled
						></v-text-field>
						<v-text-field
							v-model="editedItem.ref"
							label="Appointment Ref# "
							required
							readonly
							disabled
						></v-text-field>
						<validation-provider
							v-slot="{ errors }"
							name="Name"
							:rules="{ required: true }"
						>
							<v-text-field
								v-model="editedItem.name"
								:counter="50"
								:error-messages="errors"
								label="Name"
								required
							></v-text-field>
						</validation-provider>
						<validation-provider
							v-slot="{ errors }"
							name="NID/ Passport"
							:rules="{ required: true }"
						>
							<v-text-field
								v-model="editedItem.nid"
								:counter="50"
								:error-messages="errors"
								label="NID/ Passport"
								required
							></v-text-field>
						</validation-provider>
						<validation-provider
							v-slot="{ errors }"
							name="Nationality"
							:rules="{ required: true }"
						>
							<v-select
								v-model="editedItem.nationality"
								:items="nationalities"
								:error-messages="errors"
								label="Nationality"
								data-vv-name="select"
								class="text-dark"
								required
								light
							>
								<template v-slot:item="{ item, attrs, on }">
									<v-list-item v-bind="attrs" v-on="on">
										<v-list-item-title
											:id="attrs['aria-labelledby']"
											v-text="item"
											class="text-dark"
										>
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-select>
						</validation-provider>
						<validation-provider
							v-slot="{ errors }"
							name="Phone Number"
							:rules="{
								required: true,
								digits: 7,
								regex: '^(7|9)\\d{6}$',
							}"
						>
							<v-text-field
								v-model="editedItem.phone"
								:counter="7"
								:error-messages="errors"
								label="Phone Number"
								required
							></v-text-field>
						</validation-provider>
						<validation-provider
							v-slot="{ errors }"
							name="Insurance 1"
							:rules="{ required: true }"
						>
							<v-select
								v-model="editedItem.insA"
								:items="insList1"
								:error-messages="errors"
								label="Insurance 1"
								data-vv-name="select"
								required
							>
								<template v-slot:item="{ item, attrs, on }">
									<v-list-item v-bind="attrs" v-on="on">
										<v-list-item-title
											:id="attrs['aria-labelledby']"
											v-text="item"
											class="text-dark"
										>
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-select>
						</validation-provider>
						<validation-provider
							v-slot="{ errors }"
							name="Insurance 2"
							:rules="{ required: true }"
						>
							<v-select
								v-model="editedItem.insB"
								:items="insList2"
								:error-messages="errors"
								label="Insurance 2"
								data-vv-name="select"
								required
							>
								<template v-slot:item="{ item, attrs, on }">
									<v-list-item v-bind="attrs" v-on="on">
										<v-list-item-title
											:id="attrs['aria-labelledby']"
											v-text="item"
											class="text-dark"
										>
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-select>
						</validation-provider>
						<validation-provider
							v-slot="{ errors }"
							name="Consultation Type"
							:rules="{ required: true }"
						>
							<v-select
								v-model="editedItem.type"
								:items="[
									'Walk in consultation',
									'Online Consultation',
								]"
								:error-messages="errors"
								label="Consultation Type"
								data-vv-name="select"
								required
							>
								<template v-slot:item="{ item, attrs, on }">
									<v-list-item v-bind="attrs" v-on="on">
										<v-list-item-title
											:id="attrs['aria-labelledby']"
											v-text="item"
											class="text-dark"
										>
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-select>
						</validation-provider>
						<validation-provider
							v-slot="{ errors }"
							name="Status"
							:rules="{ required: true }"
						>
							<v-select
								v-model="editedItem.status"
								:items="[
									'New',
									'Pending Payment',
									'Completed',
									'Canceled',
									'Payment Not Done',
								]"
								:error-messages="errors"
								label="Status"
								data-vv-name="select"
								required
							>
								<template v-slot:item="{ item, attrs, on }">
									<v-list-item v-bind="attrs" v-on="on">
										<v-list-item-title
											:id="attrs['aria-labelledby']"
											v-text="item"
											class="text-dark"
										>
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-select>
						</validation-provider>
						<div v-if="editedItem.attachments" class="mb-5">
							<span>Attachments:</span>
							<ul>
								<li
									v-for="(
										attachment, i
									) in editedItem.attachments"
									:key="i"
								>
									<a
										:href="uploadsUrl(attachment.location)"
										target="_blank"
									>
										{{ attachment.name }}
									</a>
								</li>
							</ul>
						</div>
						<v-btn class="mr-4" type="submit" :disabled="invalid">
							submit
						</v-btn>
						<v-btn class="mr-4" type="submit" @click="closeEdit">
							Close
						</v-btn>
					</form>
				</validation-observer>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import {
		required,
		digits,
		email,
		max,
		regex,
	} from "vee-validate/dist/rules";
	import {
		extend,
		ValidationObserver,
		ValidationProvider,
		setInteractionMode,
	} from "vee-validate";
	import { countries } from "../components/cnts";
	import { insB } from "../components/ibsB";
	setInteractionMode("eager");

	extend("digits", {
		...digits,
		message: "{_field_} needs to be {length} digits. ({_value_})",
	});

	extend("required", {
		...required,
		message: "{_field_} can not be empty",
	});

	extend("max", {
		...max,
		message: "{_field_} may not be greater than {length} characters",
	});

	extend("regex", {
		...regex,
		message: "{_field_} {_value_} does not match {regex}",
	});

	extend("email", {
		...email,
		message: "Email must be valid",
	});
	export default {
		props: ["status", "title"],
		components: {
			ValidationProvider,
			ValidationObserver,
		},
		watch: {
			page: function () {
				if (!this.syncing) {
					this.loadData();
				}
			},
			search: function () {
				this.setSearch();
			},
			dialogEdit: function (value) {
				if (!value) {
					clearInterval(this.lockInterval);
				}
			},
		},
		mounted() {
			this.loadData();
			this.resinkInverval = setInterval(this.resync, 5000);
			this.nowint = setInterval(this.setNow, 500);
		},
		beforeUnmount() {
			clearInterval(this.resinkInverval);
			clearInterval(this.lockInterval);
			clearInterval(this.nowint);
		},
		methods: {
			setNow() {
				this.now = new Date().getTime();
			},
			timer(a, now) {
				let timerDate = new Date(a.createdAt).getTime();
				let distance = now - timerDate;
				let days = Math.floor(distance / (1000 * 60 * 60 * 24));
				let hours = Math.floor(
					(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				let minutes = Math.floor(
					(distance % (1000 * 60 * 60)) / (1000 * 60)
				);
				let seconds = Math.floor((distance % (1000 * 60)) / 1000);
				if (days) return `${days} Days ${hours}:${minutes}:${seconds}`;
				else return `${hours}:${minutes}:${seconds}`;
			},
			setSearch() {
				clearTimeout(this.searchTimeout);
				this.searchTimeout = setTimeout(this.loadData, 300);
			},
			loadData() {
				this.syncing = true;
				this.cancelResync = true;
				if (this.$store.getters.isLogin);
				if (this.search) {
					this.$http
						.get(this.reqUrl)
						.then(this.handleData)
						.catch(this.handleError);
				} else
					this.$http
						.get(this.reqUrl)
						.then(this.handleData)
						.catch(this.handleError);
			},
			handleData(r) {
				if (!this.resyncing)
					if (r.data.totalPages < r.data.currentPage) {
						if (this.page > 1) {
							this.page -= 1;
							this.loadData();
						} else {
							this.syncing = false;
						}
					} else {
						this.syncing = false;
						this.items = r.data.data;
						this.page = r.data.currentPage;
						this.pageCount = r.data.totalPages;
						this.itemsPerPage = r.data.itemsLimit;
						this.total = r.data.totalCount;
					}
				else {
					if (this.syncing) {
						this.resyncing = false;
						return;
					} else if (this.cancelResync) {
						this.resyncing = false;
						return;
					} else {
						if (r.data.totalPages < r.data.currentPage) {
							if (this.page > 1) {
								this.page -= 1;
								this.resync();
							} else {
								this.resyncing = false;
							}
						} else {
							this.resyncing = false;
							this.items = r.data.data;
							this.page = r.data.currentPage;
							this.pageCount = r.data.totalPages;
							this.itemsPerPage = r.data.itemsLimit;
							this.total = r.data.totalCount;
						}
					}
				}
			},
			handleError() {
				this.syncing = false;
			},
			resync() {
				if (this.$store.getters.isLogin)
					if (!this.syncing) {
						this.cancelResync = false;
						this.resyncing = true;
						if (this.search) {
							console.log(this.reqUrl);
							this.$http
								.get(this.reqUrl)
								.then(this.handleData)
								.catch(this.handleError);
						} else
							this.$http
								.get(this.reqUrl)
								.then(this.handleData)
								.catch(this.handleError);
					}
			},
			submit() {
				if (this.$refs.observer.validate()) {
					const submitData = {
						editinfo: {
							editUser: null,
							timestamps: 0,
						},
						insA: this.editedItem.insA,
						insB: this.editedItem.insB,
						name: this.editedItem.name,
						nationality: this.editedItem.nationality,
						nid: this.editedItem.nid,
						phone: this.editedItem.phone,
						status: this.editedItem.status,
						type: this.editedItem.type
							? this.editedItem.type
							: "Unknown",
					};
					this.$http
						.put(
							`${process.env.VUE_APP_API_ROOT}/requests/${this.editedItem._id}`,
							submitData
						)
						.then(this.handleSubmit)
						.catch(e => console.log(e.response));
				}
			},
			handleSubmit(r) {
				if (r.status == 200) {
					this.loadData();
					this.dialogEdit = false;
				}
			},
			uploadsUrl($f) {
				return `${process.env.VUE_APP_BASE_URL}uploads/${$f}`;
			},
			clear() {
				this.$refs.observer.reset();
			},
			editItem(item) {
				const id = item._id;
				const axios = this.$http;
				axios.put(
					`${process.env.VUE_APP_API_ROOT}/requests/lock/${id}`
				);
				this.loadData();
				this.lockInterval = setInterval(function () {
					axios.put(
						`${process.env.VUE_APP_API_ROOT}/requests/lock/${id}`
					);
				}, 10000);
				this.editedIndex = this.items.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialogEdit = true;
			},
			closeEdit() {
				this.dialogEdit = false;
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},
			cancelItem(item) {
				item;
				//this.editedIndex = this.items.indexOf(item)
				//this.editedItem = Object.assign({}, item)
				this.dialogCancel = true;
			},
			cancelItemConfirm() {
				console.log(`Delete this ID ${this.editedItem.id}`);
				this.closeDelete();
			},
			closeDelete() {
				this.dialogCancel = false;
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},
			getColor(stat) {
				let s = stat.toUpperCase();
				if (s == "Payment Not Done".toUpperCase())
					return { bg: "red", dark: false };
				if (s == "PENDING PAYMENT") return { bg: "yellow", dark: true };
				if (s == "NEW") return { bg: "blue", dark: false };
				if (s == "COMPLETED") return { bg: "green", dark: false };
				if (s == "CANCELED") return { bg: "red", dark: false };
				else return { bg: "", dark: false };
			},
		},
		computed: {
			reqUrl() {
				let baseUrl = `${process.env.VUE_APP_API_ROOT}/requests/search/?page=${this.page}`;
				if (this.status) {
					baseUrl += `&status=${this.status}`;
				}
				if (this.search) return `${baseUrl}&s=${this.search}`;
				return baseUrl;
			},
		},
		data() {
			return {
				now: new Date().getTime(),
				nowint: null,
				lockInterval: null,
				resinkInverval: null,
				syncing: false,
				resyncing: false,
				cancelResync: false,
				searchTimeout: null,
				nationalities: countries,
				search: null,
				sort: "ref",
				sortDir: 1,
				page: 1,
				pageCount: 0,
				itemsPerPage: 10,
				dialogEdit: false,
				dialogCancel: false,
				editedIndex: 1,
				editedItem: {},
				insList1: ["Aasandha", "None"],
				insList2: insB,
				total: 0,
				headers: [
					{
						text: "Appointment Ref#",
						align: "start",
						sortable: false,
						value: "ref",
					},
					{ text: "NID/ Passport", value: "nid", sortable: false },
					{ text: "Name", value: "name", sortable: false },
					{
						text: "Nationality",
						value: "nationality",
						sortable: false,
					},
					{ text: "Phone Number", value: "phone", sortable: false },
					{ text: "Insurance 1", value: "insA", sortable: false },
					{ text: "Insurance 2", value: "insB", sortable: false },
					{
						text: "Consultation Type",
						value: "type",
						sortable: false,
					},
					{ text: "Timer", value: "timer", sortable: false },
					{ text: "Status", value: "status", sortable: false },
					{ text: "Actions", value: "actions", sortable: false },
				],
				items: [],
			};
		},
	};
</script>

<style lang="scss">
	.table-header-item {
		margin-bottom: 0 !important;
		font-size: 14px;
		font-weight: 900;
		color: #333;
	}
	.text-dark {
		color: #333 !important;
	}
	.rotate {
		animation: rotate 1s infinite;
	}
	.editingtablenfo {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 10px;
	}
	.editingtablenfo2 {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.table-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
